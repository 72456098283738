import * as React from 'react';
import { FC, createElement } from 'react';
import { Card, Box, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { ReactNode } from 'react';

import cartouche from './cartouche.png';

const useStyles = makeStyles(theme => ({
    card: {
        minHeight: 52,
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
    },
    main: (props) => ({
        overflow: 'inherit',
        padding: 16,
        background: `url(${
            cartouche
        }) no-repeat`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .icon': {
            color: '#dc2440',
        },
    }),
    title: {},
}));

const CardWithIcon = (props) => {
    const { icon, title, subtitles, to, children } = props;
    const classes = useStyles(props);
    return (
        <Card key={title+'card'} className={classes.card}>
            <Link to={to}>
                <div className={classes.main}>
                    <Box width="3em" className="icon">
                        {createElement(icon, { fontSize: 'large' })}
                    </Box>
                    <Box textAlign="right">
                        <Typography
                            variant="h4"
                            className={classes.title}
                            color="textPrimary"
                        >
                            {title}
                        </Typography>
                        {subtitles.map((subtitle, i) =>
                            <Typography key={title+i} variant="h6" component="h2">
                                {subtitle || ' '}
                            </Typography>
                        )}
                    </Box>
                </div>
            </Link>
            {children && <Divider />}
            {children}
        </Card>
    );
};

export default CardWithIcon;
