import React from 'react';
//import { OrderList } from './orders.js';
import {required, TextInput, ReferenceArrayInput, AutocompleteArrayInput, EditButton, DeleteButton, Create, Edit, SimpleForm, SimpleShowLayout, ReferenceField, Show, NumberField, List, Datagrid, ChipField, TextField, DateField, UrlField, ReferenceInput, SelectInput, AutocompleteInput} from 'react-admin';


import {maxLength} from '../validators';
import {CustomPagination} from '../Pagination.js';

const osTypes = [
    { id: 'android', name: 'android' },
    { id: 'ios', name: 'ios' },
];

const appDescription = 'App unique identifier. Either iOS App store or Google Play app id. Example: com.discord';

export const AppShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <ReferenceField source="companyId" reference="companies" sortBy="companyId" link="show">
                <TextField source="symbol" />
            </ReferenceField>
            <TextField source="name" />
            <TextField source="uid" />
            <ChipField source="os" />
            <ChipField source="type" />
            <NumberField source="averageRating" />
            <UrlField source="iconUrl" />
            <DateField label="Created at" source="createdAt" showTime/>
            <DateField label="Updated at" source="updatedAt" showTime/>
        </SimpleShowLayout>
    </Show>
);

export const AppList = props => (
    <List {...props} exporter={false} bulkActionButtons={false}  perPage={25} pagination={<CustomPagination />} >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <ReferenceField source="companyId" reference="companies" sortBy="companyId" link="show">
                <TextField source="symbol" />
            </ReferenceField>
            <TextField source="name" />
            <TextField source="uid" />
            <ChipField source="os" />
            <ChipField source="type" />
            <NumberField source="averageRating" />
            <DateField label="Updated at" source="updatedAt" showTime/>
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
);

export const AppCreate = (props) => {
    const companyId = props.location?.state?.record?.companyId ;
    const redirect = companyId ? `/companies/${companyId}/show/apps` : "edit";

    return (
        <Create {...props} >
            <SimpleForm redirect={redirect}>
                <ReferenceInput source="companyId" reference="companies">
                    <AutocompleteInput optionText="symbol" validate={required()} />
                </ReferenceInput>
                <TextInput source="uid" helperText={appDescription} validate={required()} />
                <SelectInput source="os" choices={osTypes} validate={required()} />
                <ReferenceArrayInput
                    source="countries"
                    reference="countries"
                    perPage={300}
                    sort={{ field: 'name', order: 'ASC' }}
                    filterToQuery={ (q) => {
                        return {name: '%'+q+'%'}
                    }}
                >
                    <AutocompleteArrayInput optionText="name" validate={[required(), maxLength(20)]} />
                </ReferenceArrayInput>
            </SimpleForm>
        </Create>
    )
};

export const AppEdit = (props) => {
    const companyId = props.location?.state?.record?.companyId ;
    const redirect = companyId ? `/companies/${companyId}/show/apps` : "edit";

    return (
        <Edit {...props}>
            <SimpleForm redirect={redirect}>
                <ReferenceInput source="companyId" reference="companies">
                    <AutocompleteInput optionText="symbol" />
                </ReferenceInput>
                <TextInput source="uid" helperText={appDescription} disabled />
                <SelectInput source="os" choices={osTypes} disabled validate={required()} />
                <ReferenceArrayInput
                    source="countries"
                    reference="countries"
                    perPage={300}
                    sort={{ field: 'name', order: 'ASC' }}
                    filterToQuery={ (q) => {
                        return {name: '%'+q+'%'}
                    }}
                >
                    <AutocompleteArrayInput optionText="name" validate={[required(), maxLength(20)]} />
                </ReferenceArrayInput>
            </SimpleForm>
        </Edit>
    )
};
