import React from 'react';
//import { OrderList } from './orders.js';
import {required, EditButton, DeleteButton, Create, Edit, SimpleForm, SimpleShowLayout, ReferenceField, Show, ListView, ListController, BooleanInput, TextInput, NumberField, List, Datagrid, ChipField, TextField, BooleanField, DateField, EmailField, UrlField, Filter, ReferenceInput, SelectInput, AutocompleteInput, DateInput, SearchInput } from 'react-admin';

import {MyTooltip} from '../Tooltip.js';
import {CustomPagination} from '../Pagination.js';

const uidDescription = 'An id which identifies the company within the website (linkedin,instagram,etc..). For Linkedin its "f_C" query parameter value in the url. When searching jobs, for example for Apple Inc. on public url: https://www.linkedin.com/jobs/search?location=Worldwide&geoId=92000000&f_C=162479 The value for this field would be a number "162479"';
const nameDescription = 'Custom name for the website. If not provided second domain name is used as the name.';
const urlDescription = 'Company homepage url. Profile page url for social websites like instagram, facebook, twitter etc... Example: https://www.instagram.com/apple/';
const trackTrafficDescription = 'Enables/Disables tracking of this domain by dataforseo.com';

export const WebsiteShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <ReferenceField source="companyId" reference="companies" sortBy="companyId" link="show">
                <TextField source="symbol" />
            </ReferenceField>
            <ReferenceField source="websiteActionId" reference="website_actions" sortBy="name" link="show">
                <TextField source="id" />
            </ReferenceField>
            <TextField source="uid" />
            <TextField source="name" />
            <UrlField source="url" />
            <DateField label="Updated at" source="updatedAt" showTime/>
        </SimpleShowLayout>
    </Show>
);

export const WebsiteList = props => (
    <List {...props} exporter={false} bulkActionButtons={false} perPage={25} pagination={<CustomPagination />} >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <ReferenceField source="companyId" reference="companies" sortBy="companyId" link="show">
                <TextField source="symbol" />
            </ReferenceField>
            <ReferenceField source="websiteActionId" reference="website_actions" link="show">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="uid" />
            <TextField source="name" />
            <UrlField source="url" />
            <DateField label="Created at" source="createdAt" showTime/>
            <DateField label="Updated at" source="updatedAt" showTime/>
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
);

export const WebsiteCreate = (props) => {
    const companyId = props.location?.state?.record?.companyId ;
    const redirect = companyId ? `/companies/${companyId}/show/websites` : "edit";

    return (
        <Create {...props}>
            <SimpleForm redirect={redirect}>
                <ReferenceInput source="companyId" reference="companies">
                    <AutocompleteInput optionText="symbol" validate={required()} />
                </ReferenceInput>
                <TextInput source="uid" helperText={uidDescription} />
                <TextInput source="name" helperText={nameDescription} />
                <TextInput source="url" helperText={urlDescription} validate={required()} />
                <ReferenceInput label="Action" source="websiteActionId" reference="website_actions">
                    <SelectInput optionText="name" optionValue="id" validate={required()} />
                </ReferenceInput>
                <TextInput source="description" multiline fullWidth/>
            </SimpleForm>
        </Create>
    )
};

export const WebsiteEdit = (props) => {
    const companyId = props.location?.state?.record?.companyId ;
    const redirect = companyId ? `/companies/${companyId}/show/websites` : "edit";

    return (
        <Edit {...props}>
            <SimpleForm redirect={redirect}>
                <ReferenceInput source="companyId" reference="companies">
                    <AutocompleteInput optionText="symbol" />
                </ReferenceInput>
                <TextInput source="uid" helperText={uidDescription} />
                <TextInput source="name" helperText={nameDescription} />
                <TextInput source="url" helperText={urlDescription} />
                <ReferenceInput label="Action" source="websiteActionId" reference="website_actions">
                    <SelectInput optionText="name" optionValue="id" disabled validate={required()} />
                </ReferenceInput>
                <TextInput source="description" multiline fullWidth/>
            </SimpleForm>
        </Edit>
    )
};
