import React from 'react';
//import { OrderList } from './orders.js';
import {required, Button, FormDataConsumer, TabbedShowLayout, Tab, ReferenceManyField, ChipField, EditButton, DeleteButton, Edit, Create, SimpleForm, AutocompleteInput, SimpleShowLayout, ReferenceField, Show, ListView, ListController, BooleanInput, TextInput, NumberField, List, Datagrid, TextField, DateField, EmailField, BooleanField, Filter, ReferenceInput, SelectInput, DateInput, SearchInput } from 'react-admin';
import {CustomPagination} from '../Pagination.js';
import {useInput, useListContext, useNotify, useRedirect, useDataProvider} from 'react-admin';
import {useFormState} from 'react-final-form';
import {useForm} from 'react-final-form';

import ChipInput from '../inputs/ChipInput.js';

//import * as admin from 'react-admin';
//console.log(admin)

export const ShareOfSearchShow = (props) => (
    <Show {...props} className="share-of-searches-show" >
        <TabbedShowLayout className="share-of-searches-show-tabbed">
            <Tab label='summary'>
                <TextField source="id" />
                <TextField source="keyword" />
                <TextField source="group" />
                <TextField source="description" />
                <DateField label="Created at" source="createdAt" showTime/>
                <DateField label="Updated at" source="updatedAt" showTime/>
            </Tab>
            <Tab label='competitors' path='competitors'>
                <ReferenceManyField
                     addLabel={false}
                     reference="share_of_search_keywords"
                     target="shareOfSearchId"
                     sort={{ field: "createdAt", order: "DESC" }}
                >
                    <Datagrid>
                        <TextField source="groupName" />
                        <EditButton />
                        <DeleteButton redirect={false} />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export const ShareOfSearchList = props => (
    <List {...props} exporter={false} bulkActionButtons={false} perPage={25} pagination={<CustomPagination />} >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <ReferenceField source="companyId" reference="companies" sortBy="companyId" link="show">
                <TextField source="symbol" />
            </ReferenceField>
            <TextField source="mainBrandName" />
            <ChipField source="languageCode" />
            <ReferenceField source="countryId" reference="countries" link="show">
                <TextField source="code2" />
            </ReferenceField>
            <DateField label="Created at" source="createdAt" showTime/>
            <DateField label="Updated at" source="updatedAt" showTime/>
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
);

const CountryInput = props => {
    //const {
        //input: { name, onChange, ...rest },
        //meta: { touched, error },
        //isRequired
    //} = useInput(props);

    const form = useForm(props);
    //const inputContext = useReferenceInputContext();

    const resetLanguage = (arg1, arg2) => {
        form.change('languageCode', undefined);
    };

    return (
        <ReferenceInput
            source="countryId"
            reference="data_for_seo_countries"
            validate={required()}
            onChange={resetLanguage}
        >
            <SelectInput optionText="name" />
        </ReferenceInput>
    );
};

export const ShareOfSearchCreate = (props) => {
    const companyId = props.location?.state?.record?.companyId ;
    const redirect = companyId ? `/companies/${companyId}/show/share_of_searches` : "edit";

    return (
        <Create {...props}>
            <SimpleForm redirect={redirect} toolbar={<CreateShareOfSearchButton/>}>
                <ReferenceInput
                    validate={required()}
                    source="companyId"
                    reference="companies"
                >
                    <AutocompleteInput optionText="symbol"   />
                </ReferenceInput>
                <TextInput source="mainBrandName" label="Share of Search Name" />
                <TextInput source="description" multiline fullWidth/>
                <CountryInput />
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.countryId &&
                        <ReferenceInput
                            key={formData.countryId} //must be set so it refetches available languages
                            validate={required()}
                            source="dataForSeoCountryLanguageId"
                            label="Language"
                            reference="data_for_seo_country_languages"
                            filterToQuery={searchText => ({ 'countryId': formData.countryId })}
                            {...rest}
                        >
                            <SelectInput optionText="languageName"   />
                        </ReferenceInput>
                    }
                </FormDataConsumer>
                <ChipInput source="keywords" label="Keywords" validate={required()}/>
            </SimpleForm>
        </Create>
    )
};

export const ShareOfSearchEdit = (props) => {
    const companyId = props.location?.state?.record?.companyId ;
    const redirect = companyId ? `/companies/${companyId}/show/share_of_searches` : "edit";

    return (
        <Edit {...props}>
            <SimpleForm redirect={redirect}>
                <ReferenceInput source="companyId" reference="companies">
                    <AutocompleteInput optionText="symbol" disabled />
                </ReferenceInput>

                <TextInput source="mainBrandName" disabled />
                <ReferenceInput source="countryId" reference="countries" link="show">
                    <AutocompleteInput source="code2" disabled />
                </ReferenceInput>
                <ChipField source="languageCode" />
                <TextInput source="description" multiline fullWidth/>
            </SimpleForm>
        </Edit>
    )
};

const CreateShareOfSearchButton = ({ record, loading }) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();
    const formState = useFormState();

    const {
        // form fields related to "product" e.g.
        keywords,
        ...shareOfSearchData
      } = formState && formState.values;

    const createKeywords = (shareOfSearchData) => dataProvider
        .create('share_of_search_keywords', {
            id: record.id,
            data: {
                shareOfSearchId: shareOfSearchData.id,
                keywords
            }
        });

    const deleteShareOfSearch = (id) => dataProvider
        .delete('share_of_search', { id: id });

    const createShareOfSearch = () => dataProvider
        .create('share_of_search', { id: record.id, data: {...shareOfSearchData} })
        .then(async (response) => {
            try {
                await createKeywords(response.data);
                notify('Share of search created');

                redirect(`/share_of_search/${response.data.id}/show/competitors`);
            } catch(e) {
                await deleteShareOfSearch(response.data.id);
                notify(`Failed to create share of search competitors: ${e.message}`, 'warning');
            }
        })
        .catch(async (error) => {
            notify(`Failed to create share of search: ${error.message}`, 'warning');
        });

    return <Button label="Create" onClick={createShareOfSearch} disabled={loading} />;
};
