import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

import {useFormState} from 'react-final-form';

import { useCallback } from 'react';
import { useInput, useChoices, useSupportCreateSuggestion } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto"
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    fontWeight: 600,
  },
  list: {
    width: 300,
    height: 525,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto"
  },
  button: {
    margin: theme.spacing(0.5, 0)
  }
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function TransferList(props) {

  const {
      allowEmpty,
      choices = [],
      className,
      create,
      createLabel,
      createValue,
      disableValue,
      emptyText,
      emptyValue,
      format,
      helperText,
      label,
      loaded,
      loading,
      margin = 'dense',
      onBlur,
      onChange,
      onCreate,
      onFocus,
      options,
      optionText,
      optionValue,
      parse,
      resource,
      source,
      translateChoice,
      validate,
      title,
      ...rest
  } = props;

  //const formState = useFormState();

  const classes = useStyles();
  const [checked, setChecked] = React.useState(props.checked || []);
  const [itemList, setItem] = React.useState([]);


  const numberOfChecked = (items) => intersection(checked, items).length;


  const { getChoiceText, getChoiceValue } = useChoices({
      optionText,
      optionValue,
      translateChoice,
  });

  const renderMenuItemOption = useCallback(choice => getChoiceText(choice), [
      getChoiceText,
  ]);

  const { id, input, isRequired, meta } = useInput(props);

  const handleToggleAll = (items) => () => {
    let newChecked;
    if (numberOfChecked(items) === items.length) {
      newChecked = not(checked, items);
    } else {
      newChecked = union(checked, items);
    }

    setChecked(newChecked);
    input.onChange(newChecked.concat())
  };


  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    input.onChange(newChecked.concat());
  };

  const { touched, error, submitError } = meta;

  //const handleChange = useCallback(
      //async (event, newItem) => {
          //console.log(event);
          //console.log(newItem);
          //if (newItem) {
              //const value = getChoiceValue(newItem);
              //input.onChange(value);
              //return;
          //}

          //input.onChange(event);
      //},
      //[input, getChoiceValue]
  //);

  const customList = (title, items) => (
    <Card>

      <CardHeader
        className={classes.cardHeader}
        titleTypographyProps={{variant:'subheading' }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={typeof title === 'function' ? title(checked) : props.record[title]}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />

      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={renderMenuItemOption(value)} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>{customList(title, choices)}</Grid>
    </Grid>
  );
}
