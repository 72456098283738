import React from 'react';
import { Fragment, useState } from 'react';
//import { OrderList } from './orders.js';
import CardActions from "@material-ui/core/CardActions";
import CreateIcon from "@material-ui/icons/Create";
import EditIcon from "@material-ui/icons/Edit";
import { useUpdate, useNotify, useRedirect, Button, TopToolbar, EditButton as DefaultEditButton, Confirm } from 'react-admin';
import { ReferenceArrayInput, AutocompleteArrayInput, ArrayField, SingleFieldList, Edit, SimpleForm, Link, ListButton, RefreshButton, ChipField, UrlField, DeleteButton, TabbedShowLayout, Tab, ReferenceManyField, Show, ListView, ListController, BooleanInput, TextInput, NumberField, List, Datagrid, TextField, DateField, EmailField, BooleanField, NullableBooleanInput, Filter, ReferenceInput, SelectInput, DateInput, SearchInput, ReferenceField } from 'react-admin';
import {CustomPagination} from '../Pagination.js';
import ChipInput from '../inputs/ChipInput.js';
import SimpleChipField from '../inputs/SimpleChipField.js';
//import AutocompleteChipInput from '../inputs/AutocompleteChipInput.js';

import {CreateTagDialog} from '../components/CreateTagDialog.js';

const CompanyFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <NullableBooleanInput label="Has Apps" source="hasApps" />
        <NullableBooleanInput label="Has Websites" source="hasWebsites" />
        <NullableBooleanInput label="Has Google Trends" source="hasGoogleTrends" />
        <NullableBooleanInput label="Has Patents" source="hasPatents" />
        <NullableBooleanInput label="Has Tags" source="hasTags" />
        <NullableBooleanInput label="Is Trending" source="isTrending" />
        <ReferenceInput source="sector" reference="company_sectors">
            <SelectInput label="Sector" optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="tag.id" label="Tag" reference="tags">
            <SelectInput label="Tag" optionText="name" />
        </ReferenceInput>
    </Filter>
);

function createJobActionButton(label, id) {
    return ({ record }) => {
        const notify = useNotify();
        const [open, setOpen] = useState(false);

        const handleClick = () => setOpen(true);
        const handleDialogClose = () => setOpen(false);

        const [_fetch, { loading }] = useUpdate(
            'jobs',
            id,
            {companyId: record && record.id},
            record,
            {
                //action: 'TRIGGER_JOB',
                //mutationMode: 'undoable',
                onSuccess: () => {
                    notify('Job triggered');
                },
                onFailure: (error) => notify(`Job trigger error: ${error.message}`, { type: 'warning' }),
            }
        );

        const handleConfirm = () => {
            setOpen(false);
            return _fetch();
        };

        return <Fragment>
            <Button variant="outlined" color="secondary" disableElevation label={label} onClick={handleClick} disabled={loading} />
            <Confirm
                isOpen={open}
                loading={loading}
                title="Trigger job"
                content="Are you sure you want to trigger the job?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    };
}

const FetchPatentsButton = createJobActionButton('Fetch Patents', 'patents');
const FetchWebsitesButton = createJobActionButton('Fetch Websites', 'websites');
const FetchAppsButton = createJobActionButton('Fetch Apps', 'apps');
const FetchGoogleTrendsButton = createJobActionButton('Fetch Google Trends', 'google_trends');
const FetchShareOfSearchButton = createJobActionButton('Fetch Share of Search', 'share_of_search');
const InvalidateCacheButton = createJobActionButton('Invalidate cache', 'invalidate_cache');


const CompanyShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <DefaultEditButton basePath={basePath} record={data} />
        <FetchPatentsButton record={data} />
        <FetchWebsitesButton record={data} />
        <FetchAppsButton record={data} />
        <FetchGoogleTrendsButton record={data} />
        <FetchShareOfSearchButton record={data} />
        <InvalidateCacheButton record={data} />
    </TopToolbar>
);

//const UserShowAside = (props) => (
    //<OrderList {...props} title=" " filter={{user_id: props.record.id}} basePath="/orders" resource="orders" location={{pathname: '/orders', search: '', hash: ''}} />
//);

export const CompanyShow = (props) => (
    <Show actions={<CompanyShowActions />} {...props} className="companies-show" >
        <TabbedShowLayout className="companies-show-tabbed">
            <Tab label='summary'>
                <TextField source="name" />
                <TextField label="ticker" source="symbol" />
                <TextField source="cik" label="cik" />
                <TextField source="isin" label="isin" />
                <TextField source="exchange name" label="exchangeName" />
                <TextField source="sector" />
                <UrlField source="website" />
                <TextField source="country" />
                <BooleanField label="Has Apps" source="hasApps" />
                <BooleanField label="Has Websites" source="hasWebsites" />
                <BooleanField label="Has Google Trends" source="hasGoogleTrends" />
                <BooleanField label="Has Tags" source="hasTags" />
                <BooleanField label="Is Trending" source="isTrending" />
                <BooleanField label="Has Patents" source="hasPatents" />
                <ArrayField source="patentSearchTerms">
                    <SingleFieldList>
                        <SimpleChipField />
                    </SingleFieldList>
                </ArrayField>
                <ArrayField source="tags">
                    <SingleFieldList linkType="">
                        <ChipField source="name" />
                    </SingleFieldList>
                </ArrayField>
                <DateField label="Updated at" source="updatedAt" showTime/>
            </Tab>
            <Tab label='apps' path='apps'>
                <ReferenceManyField
                     addLabel={false}
                     reference="apps"
                     target="companyId"
                     sort={{ field: "createdAt", order: "DESC" }}
                >
                    <Datagrid>
                        <TextField source="name" />
                        <TextField source="uid" />
                        <ChipField source="os" />
                        <ChipField source="type" />
                        <EditButton pathname="/apps/:id" />
                        <DeleteButton redirect={false} />
                    </Datagrid>
                </ReferenceManyField>
                <CreateButton pathname="/apps/create" label="Create an App" />
            </Tab>
            <Tab label='websites' path='websites'>
                <ReferenceManyField
                     addLabel={false}
                     reference="websites"
                     target="companyId"
                     sort={{ field: "createdAt", order: "DESC" }}
                >
                    <Datagrid>
                        <TextField source="name" />
                        <ChipField source="uid" />
                        <UrlField source="url" />
                        <ReferenceField source="websiteActionId" reference="website_actions" link="show">
                            <TextField source="name" />
                        </ReferenceField>
                        <EditButton pathname="/websites/:id" />
                        <DeleteButton redirect={false} />
                    </Datagrid>
                </ReferenceManyField>
                <CreateButton pathname="/websites/create" label="Create a Website" />
            </Tab>
            <Tab label='google trends' path='google_trends'>
                <ReferenceManyField
                     addLabel={false}
                     reference="google_trend"
                     target="companyId"
                     sort={{ field: "createdAt", order: "DESC" }}
                >
                    <Datagrid>
                        <TextField source="keyword" />
                        <BooleanField source="isPrimary" />
                        <TextField source="group" />
                        <EditButton pathname="/google_trend/:id" />
                        <DeleteButton redirect={false} />
                    </Datagrid>
                </ReferenceManyField>
                <CreateButton pathname="/google_trend/create" label="Create a Keyword" />
            </Tab>
            <Tab label='share of search' path='share_of_search'>
                <ReferenceManyField
                     addLabel={false}
                     reference="share_of_search"
                     target="companyId"
                     sort={{ field: "createdAt", order: "DESC" }}
                >
                    <Datagrid>
                        <TextField source="mainBrandName" />
                        <BooleanField source="languageCode" />
                        <TextField source="country" />
                        <EditButton pathname="/share_of_search/:id/show/competitors" />
                        <DeleteButton redirect={false} />
                    </Datagrid>
                </ReferenceManyField>
                <CreateButton pathname="/share_of_search/create" label="Create" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export const CompanyList = props => (
    <List {...props} exporter={false} bulkActionButtons={false} filters={<CompanyFilter />} perPage={25} pagination={<CustomPagination />} >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="name" />
            <TextField label="ticker" source="symbol" />
            <TextField label="cik" source="cik" />
            <TextField label="exchange name" source="exchangeName" />
            <TextField source="sector" />
            <TextField source="country" />
            <BooleanField label="Has Apps" source="hasApps" />
            <BooleanField label="Has Websites" source="hasWebsites" />
            <BooleanField label="Has Google Trends" source="hasGoogleTrends" />
            <BooleanField label="Has Patents" source="hasPatents" />
            <BooleanField label="Has Tags" source="hasTags" />
            <BooleanField label="Is Trending" source="isTrending" />
            <ArrayField source="tags">
                <SingleFieldList linkType="">
                    <ChipField source="name" />
                </SingleFieldList>
            </ArrayField>
        </Datagrid>
    </List>
);

export const CompanyEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <BooleanInput source="isTrending" />
            <ChipInput source="patentSearchTerms" label="Patent search terms" />
            <ReferenceArrayInput
                label="Tags"
                reference="tags"
                source="tags"
                filterToQuery={ (q) => {
                    return {name: '%'+q+'%'}
                }}
                format={value => {
                    return value && value.map(v => v.id)
                }}
                parse={value => {
                    return value && value.map(v => ({ id: v }))
                } }
            >
                <AutocompleteArrayInput
                    debounce={500}
                    create={<CreateTagDialog />}

                    parse={value => {
                        return value && value.map(v => ({ id: v }))
                    } }
                    format={value => {
                        return value && value.map(v => v.id)
                    }}
                    //onCreate={() => {
                        //const newTagName = prompt('Enter a new tag');
                        //const newTag = { id: newTagName.toLowerCase(), name: newTagName };
                        //tags.push(newTag);
                        //return newTag;
                    //}}
                />
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
);

const CreateButton = ({ record, label, pathname }) => (
  <Button
    component={Link}
    to={{
      pathname: pathname,
      // Here we specify the initial record for the create view
      state: { record: { companyId: record.id }},
    }}
    label={label}
  >
    <CreateIcon />
  </Button>
);

const EditButton = ({ record, pathname }) => {
  return (
    <Button
      component={Link}
      to={{
        pathname: pathname.replace(':id', record.id),
        // Here we specify the initial record for the edit view
        state: { record: { companyId: record.companyId }},
      }}
      label='Edit'
    >
      <EditIcon />
    </Button>
  )
};

//const CompanyShowActions = ({ basePath, data }) => (
  //<CardActions>
    //<ListButton basePath={basePath} />
    //<RefreshButton />
  //</CardActions>
//);
