import React from 'react';
//import { OrderList } from './orders.js';
import { downloadCSV, ReferenceField, ListView,  List, Datagrid, TextField,  ReferenceInput } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import {CustomPagination} from '../Pagination.js';


const exporter = data => {
    jsonExport(data, {
        headers: ['id', 'symbol', 'name', 'watchersCount'] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'beat-market-watchlist-stats');
    });
};

export const WatchlistStatsList = props => (
    <List {...props} exporter={exporter} bulkActionButtons={false} perPage={25} pagination={<CustomPagination />} >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <ReferenceField source="id" reference="companies" link="show">
                <TextField source="symbol" />
            </ReferenceField>
            <TextField source="name" />
            <TextField source="watchersCount" />
        </Datagrid>
    </List>
);

