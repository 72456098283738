
export function abbrevNum (value, decimals = 2) {
  if (value === null || value === undefined) {
    return null;
  } else if (value == 'N/A') {
      return value;
  }
  const n = Math.abs(value)
  if (n < 1e3) return toFixedDecimals(+(value), decimals)
  if (n >= 1e3 && n < 1e6) return toFixedDecimals(+(value / 1e3), decimals) + 'K';
  if (n >= 1e6 && n < 1e9) return toFixedDecimals(+(value / 1e6), decimals) + 'M';
  if (n >= 1e9 && n < 1e12) return toFixedDecimals(+(value / 1e9), decimals) + 'B';
  if (n >= 1e12) return toFixedDecimals(+(value / 1e12), decimals) + 'T';
}

export function toFixedDecimals (value = 0, decimals = 2, plus = false) {
  if (value === null) {
    return 0;
  }
  const re = new RegExp('^-?\\d+(?:.\\d{0,' + (decimals || -1) + '})?');
  const match = value.toString().match(re);
  if (match) {
    return (plus && value > 0 ? '+' : '') + match[0];
  } else {
    return null;
  }
}
