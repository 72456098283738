import * as React from 'react';
import CustomerIcon from '@material-ui/icons/PersonAdd';
import { useTranslate } from 'react-admin';

import CardWithIcon from './cardWithIcon.js';

const StatsCard = (props) => {
    const { stats, title } = props;
    const translate = useTranslate();
    const subtitles = [
        `Today: ${stats.today}`,
        `Last 7 Days: ${stats.lastSevenDays}`,
        `Last 30 Days: ${stats.lastThirtyDays}`,
    ];
    return (
        <CardWithIcon
            to="/users"
            icon={CustomerIcon}
            title={title}
            subtitles={subtitles}
        />
    );
};

export default StatsCard;
