import React, { useState, useEffect, useCallback } from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Item from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import { Title, useDataProvider, useVersion } from 'react-admin';
import StatsCard from './statsCard.js';
import {dashboardDataFetcher} from '../dataFetcher.js';
import {LineChart} from '../components/LineChart.js';

const VerticalSpacer = () => <span style={{ height: '2em' }} />;

export default () => {
    const version = useVersion();
    const [state, setState] = useState({
        registeredUsersStats: {
            id: 0,
            today: 0,
            lastSevenDays: 0,
            lastThirtyDays: 0
        },
        activeUsersStats: {
            id: 1,
            today: 0,
            lastSevenDays: 0,
            lastThirtyDays: 0
        },
        history: {
            activeUsers: [],
            registeredUsers: []
        }
    });
    const dataProvider = useDataProvider();

    const fetchDashboardData = useCallback(async () => {
        const data = await dashboardDataFetcher();
        //const data = await dataProvider.getOne(
            //'dashboard',
            //{
                //id: ''
            //}
        //);

        const registeredUsersStats = {
            id: 0,
            today: data.registeredToday,
            lastSevenDays: data.registeredInLast7Days,
            lastThirtyDays: data.registeredInLast30Days
        };

        const activeUsersStats = {
            id: 1,
            today: data.activeToday,
            lastSevenDays: data.activeInLast7Days,
            lastThirtyDays: data.activeInLast30Days
        };

        const history = data.history;

        setState(state => ({
            ...state,
            registeredUsersStats,
            activeUsersStats,
            history
        }));
    }, [dataProvider]);

    useEffect(() => {
        fetchDashboardData();
    }, [version]);

    const {
        registeredUsersStats,
        activeUsersStats,
        history
    } = state;

    const registeredUsersLabels = [];
    const registeredUsersData = {
        data: [],
        avg1: [],
        avg2: []
    };

    for (const item of history.registeredUsers) {
        registeredUsersLabels.push(item.date);
        registeredUsersData.data.push(item.registeredUsersCount);
        registeredUsersData.avg1.push(item.movingAvg7Days);
        registeredUsersData.avg2.push(item.movingAvg30Days);
    }

    const activeUsersLabels = [];
    const activeUsersData = {
        data: [],
        avg1: [],
        avg2: []
    };

    for (const item of history.activeUsers) {
        activeUsersLabels.push(item.date);
        activeUsersData.data.push(item.activeUsersCount);
        activeUsersData.avg1.push(item.movingAvg7Days);
        activeUsersData.avg2.push(item.movingAvg30Days);
    }

    return (
        <>
            <Grid
              container
              direction="row"
              justifycontent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
                <Grid item xs={3}>
                    <Item>
                        <StatsCard key="new-users" title="New Users" stats={registeredUsersStats} />
                    </Item>
                </Grid>
                <Grid item xs={3}>
                    <Item>
                        <StatsCard key='active-users' title="Active Users" stats={activeUsersStats} />
                    </Item>
                </Grid>
            </Grid>
            <VerticalSpacer />
            <Grid
              container
              direction="row"
              justifycontent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
                <Grid item xs={12}>
                    <Item>
                        { registeredUsersLabels.length > 0 && <LineChart title="New users growth" data={registeredUsersData} labels={registeredUsersLabels} />}
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item>
                        {activeUsersLabels.length > 0 && <LineChart title="Active users growth" data={activeUsersData} labels={activeUsersLabels} />}
                    </Item>
                </Grid>
            </Grid>
        </>
    );
};
