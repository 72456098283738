import React from 'react';
//import { OrderList } from './orders.js';
import {TopToolbar, ExportButton, downloadCSV, Edit, SimpleForm, SimpleShowLayout, BooleanField, Show, TextInput, PasswordInput, List, Datagrid, TextField, DateField} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import {CustomPagination} from '../Pagination.js';

const exporter = data => {
    jsonExport(data, { }, (err, csv) => {
        downloadCSV(csv, 'beat-market-users');
    });
};

const ListActions = (props) => (
    <TopToolbar>
        <ExportButton maxResults={0} />
    </TopToolbar>
);

export const UserShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="uuid" />
            <TextField source="email" />
            <TextField source="first_name" />
            <TextField source="last_name" />
            <TextField source="country_code_2" />
            <TextField source="city" />
            <TextField source="address" />
            <TextField source="zip" />
            <BooleanField source="newsletter" />
            <DateField label="Activated at" source="activatedAt" showTime/>
            <DateField label="Updated at" source="updatedAt" showTime/>
            <DateField label="Created at" source="createdAt" showTime/>
        </SimpleShowLayout>
    </Show>
);

export const UserList = props => (
    <List {...props} exporter={exporter} actions={<ListActions />} bulkActionButtons={false}  perPage={25} pagination={<CustomPagination />} >
        <Datagrid rowClick="show">
            <TextField source="email" />
            <TextField source="first_name" />
            <TextField source="last_name" />
            <DateField label="Activated at" source="activatedAt" showTime/>
            <DateField label="Last Logged at" source="lastLoggedAt" showTime/>
            <DateField label="Updated at" source="updatedAt" showTime/>
            <DateField label="Created at" source="createdAt" showTime/>
            <TextField label="Watchlist tickers" source="tickers" />
        </Datagrid>
    </List>
);

export const UserEdit = (props) => {
    return (
        <Edit {...props} undoable={false}>
            <SimpleForm >
                <TextInput source="email" disabled />
                <TextInput source="first_name" />
                <TextInput source="last_name" />
                <TextInput source="country_code_2" />
                <TextInput source="city" />
                <TextInput source="address" />
                <TextInput source="zip" />
                <PasswordInput source="password" />
                <PasswordInput source="password_confirmation" />
            </SimpleForm>
        </Edit>
    )
};
