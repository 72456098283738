import React from 'react';
import {required, EditButton, DeleteButton, Datagrid, Create, Edit, SimpleForm, SimpleShowLayout, ReferenceField, Show, ListView, BooleanInput, TextInput, NumberField, List, ChipField, TextField, DateField, EmailField, UrlField, Filter, SelectInput, DateInput, SearchInput } from 'react-admin';
import { useFormState } from 'react-final-form';

import {maxLength} from '../validators';
import {CustomPagination} from '../Pagination.js';

const screenerUrlDescription = 'Url to screener like this: https://strike.market/screener?appRank%5Bfrom%5D=1&appRank%5Bto%5D=1';
const titleDescription = 'Generic title. If `h1` or `seoTitle` fields are empty, generic title will be used instead. Otherwise this title could be used somewhere on page UI elements, it should be pretty version of title without any seo keywords';
const h1Description = 'Title that will be used on page as content of <h1> tag';
const seoTitleDescription = 'Title that will be used as page title - as content of <title> header tag';

const SlugInput = props => {
    const { values } = useFormState();
    return (
        <TextInput
            initialValue={values.title ? values.title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '') : ''}
            {...props}
        />
    );
};

export const ScreenerShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="title" />
            <TextField source="h1" />
            <TextField source="seoTitle" />
            <TextField source="slug" />
            <TextField source="description" />
            <UrlField source="screenerUrl" />
            <DateField label="Created at" source="createdAt" showTime/>
            <DateField label="Updated at" source="updatedAt" showTime/>
        </SimpleShowLayout>
    </Show>
);

export const ScreenerList = props => (
    <List {...props} exporter={false} bulkActionButtons={false}  perPage={25} pagination={<CustomPagination />} filter={{ articleTypeId: 1 }} >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="title" />
            <TextField source="slug" />
            <UrlField source="screenerUrl" />
            <DateField label="Updated at" source="updatedAt" showTime/>
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
);

export const ScreenerCreate = (props) => {
    const redirect = `/screeners` || "edit";

    return (
        <Create {...props} >
            <SimpleForm redirect={redirect}>
                <TextInput source="title" helperText={titleDescription} validate={[required(), maxLength(255)]} />
                <TextInput source="h1" helperText={h1Description} validate={[maxLength(255)]} />
                <TextInput source="seoTitle" helperText={seoTitleDescription} validate={[maxLength(255)]} />
                <SlugInput source="slug" validate={[required(), maxLength(255)]} />
                <TextInput source="screenerUrl" helperText={screenerUrlDescription} validate={[required(), maxLength(1024)]} />
                <TextInput source="description" multiline fullWidth validate={maxLength(1024)}/>
            </SimpleForm>
        </Create>
    )
};

export const ScreenerEdit = (props) => {
    const redirect = `/screeners` || "edit";

    return (
        <Edit {...props}>
            <SimpleForm redirect={redirect}>
                <TextInput source="title" helperText={titleDescription} validate={[required(), maxLength(255)]} />
                <TextInput source="h1" helperText={h1Description} validate={[maxLength(255)]} />
                <TextInput source="seoTitle" helperText={seoTitleDescription} validate={[maxLength(255)]} />
                <SlugInput source="slug" validate={[required(), maxLength(255)]} disabled />
                <TextInput source="screenerUrl" helperText={screenerUrlDescription} validate={[required(), maxLength(1024)]} />
                <TextInput source="description" multiline fullWidth validate={maxLength(1024)}/>
            </SimpleForm>
        </Edit>
    )
};
