import React from 'react';
//import { OrderList } from './orders.js';
import {required, FormDataConsumer, EditButton, DeleteButton, Edit, Create, SimpleForm, AutocompleteInput, SimpleShowLayout, ReferenceField, Show, ListView, ListController, BooleanInput, TextInput, NumberField, List, Datagrid, TextField, DateField, EmailField, BooleanField, Filter, ReferenceInput, SelectInput, DateInput, SearchInput } from 'react-admin';
import {CustomPagination} from '../Pagination.js';


const isPrimaryDescription = 'if set, other non-primary keywords within the same group will be grouped into a single feed event alongside this primary keyword. Only single keyword in a group can be set as primary at the time..'
const separateInFeedDescription = 'Flag for feed template. Allows to generate special feed text for the keyword group.'

export const GoogleTrendShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="keyword" />
            <TextField source="group" />
            <DateField label="Created at" source="createdAt" showTime/>
            <DateField label="Updated at" source="updatedAt" showTime/>
        </SimpleShowLayout>
    </Show>
);

export const GoogleTrendList = props => (
    <List {...props} exporter={false} bulkActionButtons={false} perPage={25} pagination={<CustomPagination />} >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <ReferenceField source="companyId" reference="companies" sortBy="companyId" link="show">
                <TextField source="symbol" />
            </ReferenceField>
            <BooleanField source="isPrimary" />
            <BooleanField source="separateInFeed" />
            <TextField source="keyword" />
            <TextField source="group" />
            <DateField label="Created at" source="createdAt" showTime/>
            <DateField label="Updated at" source="updatedAt" showTime/>
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
);

export const GoogleTrendCreate = (props) => {
    const companyId = props.location?.state?.record?.companyId ;
    const redirect = companyId ? `/companies/${companyId}/show/google_trends` : "edit";

    return (
        <Create {...props}>
            <SimpleForm redirect={redirect}>
                <ReferenceInput source="companyId" reference="companies">
                    <AutocompleteInput optionText="symbol" validate={required()} />
                </ReferenceInput>
                <TextInput source="keyword" validate={required()}  />
                <TextInput source="group" />
                <BooleanInput source="isPrimary" helperText={isPrimaryDescription} />
                <FormDataConsumer>
                {({ formData, ...rest }) => formData.isPrimary &&
                    <BooleanInput source="separateInFeed" helperText={separateInFeedDescription} />
                }
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    )
};

export const GoogleTrendEdit = (props) => {
  const companyId = props.location?.state?.record?.companyId ;
  const redirect = companyId ? `/companies/${companyId}/show/google_trends` : "edit";

  return (
    <Edit {...props} >
        <SimpleForm redirect={redirect}>
            <ReferenceInput source="companyId" reference="companies">
                <AutocompleteInput optionText="symbol" validate={required()}  />
            </ReferenceInput>
            <TextInput source="keyword" validate={required()} />
            <TextInput source="group" />
            <BooleanInput source="isPrimary" helperText={isPrimaryDescription} />
            <FormDataConsumer>
            {({ formData, ...rest }) => formData.isPrimary &&
                <BooleanInput source="separateInFeed" helperText={separateInFeedDescription} />
            }
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
  )
};
