import React from 'react';
import {required, EditButton, ReferenceField, ReferenceInput, AutocompleteInput, DeleteButton, Datagrid, Edit, Create, SimpleForm, SimpleShowLayout, Show, TextInput, List, TextField, DateField, DateTimeInput} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useFormState } from 'react-final-form';
import Quill from "quill";

import {CustomPagination} from '../Pagination.js';
import {maxLength} from '../validators';

const ColorClass = Quill.import('attributors/class/color');
const BackgroundClass = Quill.import('attributors/class/background');

Quill.register(ColorClass, true);
Quill.register(BackgroundClass, true);

const imageHandler =  function imageHandler() {
    var range = this.quill.getSelection();
    var value = prompt('What is the image URL');
    if(value){
        this.quill.insertEmbed(range.index, 'image', value, Quill.sources.USER);
    }
}
const toolbar = {
    container: [
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values

        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        //['blockquote', 'code-block'],
        ['blockquote'],

        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        //[{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        //[{ 'font': [] }],
        [{ 'align': [] }],

        ['clean'],
        ['link', 'image', 'video'],
    ],
    handlers: {
        image: imageHandler
    }
};

const quillOptions = {
    modules: {
        toolbar
    },
    formats: ['link', 'size', 'bold', 'italic', 'strike', 'script', 'underline', 'blockquote', 'header', 'indent', 'list', 'align', 'direction', 'image', 'video']
};

const SlugInput = props => {
    const { values } = useFormState();
    return (
        <TextInput
            initialValue={values.title ? values.title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '') : ''}
            {...props}
        />
    );
};

const titleDescription = 'Generic title. If `h1` or `seoTitle` fields are empty, generic title will be used instead. Otherwise this title could be used somewhere on page UI elements, it should be pretty version of title without any seo keywords';
const h1Description = 'Title that will be used on page as content of <h1> tag';
const seoTitleDescription = 'Title that will be used as page title - as content of <title> header tag';

export const ArticleShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <ReferenceField source="articleCategoryId" reference="article_categories" sortBy="articleCategoryId" link="show">
                <TextField source="type" />
            </ReferenceField>
            <TextField source="title" />
            <TextField source="h1" />
            <TextField source="seoTitle" />
            <TextField source="slug" />
            <ReferenceField source="hostId" reference="peoples" sortBy="hostId" link="show">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="authorId" reference="peoples" sortBy="authorId" link="show">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="imageUrl" />
            <TextField source="description" multiline fullWidth />
            <TextField source="content" multiline fullWidth />
            <DateField label="Published At" source="publishedAt" showTime />
            <DateField label="Updated at" source="updatedAt" showTime/>
        </SimpleShowLayout>
    </Show>
);

export const ArticleList = props => (
    <List {...props} exporter={false} bulkActionButtons={false}  perPage={25} pagination={<CustomPagination />} filter={{ articleTypeId: 5 }} >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <ReferenceField source="articleCategoryId" reference="article_categories" sortBy="articleCategoryId" link="show">
                <TextField source="type" />
            </ReferenceField>
            <TextField source="title" />
            <ReferenceField source="hostId" reference="peoples" sortBy="hostId" link="show">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="authorId" reference="peoples" sortBy="authorId" link="show">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="slug" />
            <DateField label="Published At" source="publishedAt" showTime />
            <DateField label="Updated at" source="updatedAt" showTime/>
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
);

export const ArticleEdit = (props) => {
    const redirect = `/articles` || "edit";

    return (
        <Edit {...props}>
            <SimpleForm redirect={redirect}>
                <TextInput source="title" helperText={titleDescription} validate={[required(), maxLength(255)]} />
                <TextInput source="h1" helperText={h1Description} validate={[maxLength(255)]} />
                <TextInput source="seoTitle" helperText={seoTitleDescription} validate={[maxLength(255)]} />
                <ReferenceInput
                    source="articleCategoryId"
                    reference="article_categories"
                    filterToQuery={ (q) => {
                        return {type: '%'+q+'%'}
                    }}
                >
                    <AutocompleteInput optionText="type" validate={required()} />
                </ReferenceInput>
                <SlugInput source="slug" validate={[required(), maxLength(255)]} disabled />
                <TextInput source="imageUrl" validate={[maxLength(512)]} />
                <ReferenceInput
                    source="hostId"
                    reference="peoples"
                    filterToQuery={ (q) => {
                        return {name: '%'+q+'%'}
                    }}
                >
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput
                    source="authorId"
                    reference="peoples"
                    filterToQuery={ (q) => {
                        return {name: '%'+q+'%'}
                    }}
                >
                    <AutocompleteInput optionText="name" validate={required()} />
                </ReferenceInput>
                <DateTimeInput source="publishedAt" />
                <RichTextInput options={quillOptions} source="description" />
                <RichTextInput options={quillOptions} source="content" />
            </SimpleForm>
        </Edit>
    )
};

export const ArticleCreate = (props) => {
    const redirect = `/articles` || "edit";

    return (
        <Create {...props} >
            <SimpleForm redirect={redirect}>
                <ReferenceInput
                    source="articleCategoryId"
                    reference="article_categories"
                    filterToQuery={ (q) => {
                        return {type: '%'+q+'%'}
                    }}
                >
                    <AutocompleteInput optionText="type" validate={required()} />
                </ReferenceInput>
                <TextInput source="title" helperText={titleDescription} validate={[required(), maxLength(255)]} />
                <TextInput source="h1" helperText={h1Description} validate={[maxLength(255)]} />
                <TextInput source="seoTitle" helperText={seoTitleDescription} validate={[maxLength(255)]} />
                <SlugInput source="slug" validate={[required(), maxLength(255)]} disabled />
                <TextInput source="imageUrl" validate={[maxLength(512)]} />
                <ReferenceInput
                    source="hostId"
                    reference="peoples"
                    filterToQuery={ (q) => {
                        return {name: '%'+q+'%'}
                    }}
                >
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput
                    source="authorId"
                    reference="peoples"
                    filterToQuery={ (q) => {
                        return {name: '%'+q+'%'}
                    }}
                >
                    <AutocompleteInput optionText="name" validate={required()} />
                </ReferenceInput>
                <DateTimeInput source="publishedAt" />
                <RichTextInput options={quillOptions} source="description" />
                <RichTextInput options={quillOptions} source="content" />
            </SimpleForm>
        </Create>
    )
};
