import React from 'react';
//import { OrderList } from './orders.js';
import { Edit, EditButton, SimpleForm, SimpleShowLayout, ReferenceField, Show, ListView, ListController, BooleanInput, TextInput, NumberField, List, Datagrid, TextField, DateField, EmailField, BooleanField, Filter, ReferenceInput, SelectInput, DateInput, SearchInput} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {CustomPagination} from '../Pagination.js';


export const FeedConfigShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="titleTemplate" />
            <TextField source="descriptionTemplate" />
            <TextField source="paramsTemplate" />
            <DateField label="Created at" source="createdAt" showTime/>
            <DateField label="Updated at" source="updatedAt" showTime/>
        </SimpleShowLayout>
    </Show>
);

export const FeedConfigEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput source="titleTemplate" multiline fullWidth/>
            <TextInput source="descriptionTemplate" multiline fullWidth/>
            <TextInput source="paramsTemplate" multiline fullWidth/>
        </SimpleForm>
    </Edit>
);

export const FeedConfigList = props => (
    <List {...props} exporter={false} bulkActionButtons={false} perPage={25} pagination={<CustomPagination />} >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <ReferenceField source="metricTypeId" reference="metric_types" sortBy="type" link="show">
                <TextField source="type" />
            </ReferenceField>
            <ReferenceField source="feedEventTypeId" reference="feed_event_types" sortBy="name" link="show">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="titleTemplate" />
            <DateField label="Created at" source="createdAt" showTime/>
            <DateField label="Updated at" source="updatedAt" showTime/>
            <EditButton/>
        </Datagrid>
    </List>
);

