import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    TimeScale,
    TimeSeriesScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    TimeSeriesScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


export class LineChart extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            options:{
                scales: {
                    x: {
                        type: 'timeseries',
                        title: {
                            display: true,
                            text: 'Date'
                        },
                        time: {
                            parser: 'yyyy-MM-dd',
                            unit: 'day',
                        }
                    },
                    y: {
                        type: 'linear',
                        beginAtZero: true
                    }

                },
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: props.title,
                    },
                },
            },
            data: {
                labels: props.labels || [],
                datasets: [
                    {
                        label: 'Count',
                        data: props.data.data,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                    {
                        label: '7 Day Moving Average',
                        data: props.data.avg1,
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                    {
                        label: '30 Day Moving Average',
                        data: props.data.avg2,
                        borderColor: 'rgb(99, 255, 208)',
                        backgroundColor: 'rgba(99, 255, 208, 0.5)',
                    },
                ],
            }
        };
    }

    render() {
        return <Line redraw={true} options={this.state.options} data={this.state.data} />;
    }
}

