const authProvider = {
    login: ({ username, password }) =>  {
        const request = new Request('/login', {
            method: 'POST',
            body: JSON.stringify({ email:username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                localStorage.setItem('auth', JSON.stringify(auth));
            })
            .catch(() => {
                throw new Error('Network error')
            });
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    logout: () => {
        localStorage.removeItem('auth');
        const request = new Request('/logout', { method: 'POST', });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
            });
    },
    checkAuth: () => localStorage.getItem('auth')
    ? Promise.resolve()
    : Promise.reject(),
    getIdentity: () => { /* ... */ },
    getPermissions: () => {
        return Promise.resolve();
    }
};

export default authProvider;
