import React from 'react';
//import { OrderList } from './orders.js';
import {Button, SingleFieldList, EditButton, DeleteButton, Create, Edit, SimpleForm, SimpleShowLayout, ReferenceField, Show, ListView, ListController, BooleanInput, TextInput, NumberField, List, Datagrid, ChipField, TextField, DateField, EmailField, UrlField, Filter, ReferenceInput, SelectInput, AutocompleteInput, DateInput, SearchInput } from 'react-admin';

import {CustomPagination} from '../Pagination.js';

import {useMutation, useNotify, useRedirect} from 'react-admin';
import {useFormState} from 'react-final-form';

import {abbrevNum} from '../utils.js';
import TransferList from '../inputs/SelectBoxInput.js';

//export const ShareOfSearchKeywordShow = (props) => (
    //<Show {...props}>
        //<SimpleShowLayout>
            //<TextField source="id" />
            //<ReferenceField source="companyId" reference="companies" sortBy="companyId" link="show">
                //<TextField source="symbol" />
            //</ReferenceField>
            //<TextField source="name" />
            //<TextField source="uid" />
            //<ChipField source="os" />
            //<ChipField source="type" />
            //<NumberField source="averageRating" />
            //<UrlField source="iconUrl" />
            //<SingleFieldList>
                //<ChipField  source="name" />
            //</SingleFieldList>
            //<DateField label="Created at" source="createdAt" showTime/>
            //<DateField label="Updated at" source="updatedAt" showTime/>
        //</SimpleShowLayout>
    //</Show>
//);

const WithProps = ({children,...props}) => children(props);

export const ShareOfSearchKeywordEdit = (props) => {
    const shareOfSearchId = props.location?.state?.record?.shareOfSearchId ;
    const redirect = shareOfSearchId ? `/share_of_search/${shareOfSearchId}/show/share_of_search_keywords` : "edit";
    return (
        <Edit {...props} >
          <WithProps>{({record,...props})=>
            <SimpleForm record={record} toolbar={<CustomEditButton/>} {...props} >
                <TransferList
                    source="keywords"
                    title={(keywords) => {
                        const sum = keywords.reduce((sum, k) => {
                            sum += record.suggestedKeywords[k];
                            return sum;
                        }, 0);
                        return `${record.groupName} (${abbrevNum(sum)})`;
                    }}
                    optionText={c=>{
                      return `${c} (${abbrevNum(record.suggestedKeywords[c])})`
                    }}
                    optionValue={c=>c}
                    checked={record.keywords}
                    choices={Object.keys(record.suggestedKeywords)}
                />
                <BooleanInput source="isPrimary" />
            </SimpleForm>}
          </WithProps>
        </Edit>
    )
};

const CustomEditButton = ({ record }) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const formState = useFormState();

    const [approve, { loading }] = useMutation(
      {
        type: 'update',
        resource: 'share_of_search_keywords',
        payload: { id: record.id, data: formState.values  },
      },
      {
        onSuccess: ({ data }) => {
          redirect(`/share_of_search/${formState.values.shareOfSearchId}/show/competitors`);
          notify('Succesfully updated');
        },
        onFailure: (error) => notify(`Comment approval error: ${error.message}`, 'warning'),
      }
    );

    return <Button label="Save" onClick={approve} disabled={loading} />;
};
