import React from 'react';
//import { OrderList } from './orders.js';
import CardActions from "@material-ui/core/CardActions";
import { EditButton, SingleFieldList, Edit, SimpleForm, Link, ListButton, RefreshButton, ChipField, UrlField, DeleteButton, SimpleShowLayout, Tab, ReferenceManyField, Show, ListView, ListController, BooleanInput, TextInput, NumberField, List, Datagrid, TextField, DateField, EmailField, BooleanField, NullableBooleanInput, Filter, ReferenceInput, SelectInput, DateInput, SearchInput, ReferenceField } from 'react-admin';
import {CustomPagination} from '../Pagination.js';
import ChipInput from '../inputs/ChipInput.js';
import SimpleChipField from '../inputs/SimpleChipField.js';

const InstitutionFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Institution name" source="name" />
        <TextInput source="cik" />
    </Filter>
);

//const UserShowAside = (props) => (
    //<OrderList {...props} title=" " filter={{user_id: props.record.id}} basePath="/orders" resource="orders" location={{pathname: '/orders', search: '', hash: ''}} />
//);


export const InstitutionShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="managerName" />
            <TextField source="cik" source="cik" />
            <DateField label="Created at" source="createdAt" showTime/>
            <DateField label="Updated at" source="updatedAt" showTime/>
        </SimpleShowLayout>
    </Show>
);

export const InstitutionList = props => (
    <List {...props} exporter={false} bulkActionButtons={false} filters={<InstitutionFilter />} perPage={25} pagination={<CustomPagination />} >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField label="cik" source="cik" />
            <TextField source="name" />
            <TextField source="managerName" />
            <DateField label="Updated at" source="updatedAt" showTime/>
            <EditButton />
        </Datagrid>
    </List>
);

export const InstitutionEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" disabled />
            <TextInput source="managerName" />
        </SimpleForm>
    </Edit>
);
