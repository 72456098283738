import { fetchUtils } from 'react-admin';
const { fetchJson } = fetchUtils;

export const websiteActionListFetcher = () => {
    return fetchJson('/api/v1.0/website_actions')
        .then(response => response.json);
}

export const dashboardDataFetcher = () => {
    return fetchJson('/api/v1.0/dashboard')
        .then(response => response.json);
}

export const companySectorListFetcher = () => {
    return fetchJson('/api/v1.0/company_sectors')
        .then(response => response.json);
}
