import React from 'react';
import {required, EditButton, DeleteButton, Datagrid, Create, Edit, SimpleForm, SimpleShowLayout, Show, TextInput, List, TextField, DateField, UrlField } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import {maxLength} from '../validators';
import {CustomPagination} from '../Pagination.js';

import Quill from "quill";

const ColorClass = Quill.import('attributors/class/color');
const BackgroundClass = Quill.import('attributors/class/background');

Quill.register(ColorClass, true);
Quill.register(BackgroundClass, true);

const toolbar = {
    container: [
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values

        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        //['blockquote', 'code-block'],
        ['blockquote'],

        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        //[{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        //[{ 'font': [] }],
        [{ 'align': [] }],

        ['clean'],
        ['link', 'image', 'video'],
    ],
    handlers: {
    }
};

const quillOptions = {
    modules: {
        toolbar
    },
    formats: ['link', 'size', 'bold', 'italic', 'strike', 'script', 'underline', 'blockquote', 'header', 'indent', 'list', 'align', 'direction', 'image', 'video']
};


export const PeopleShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="description" multiline fullWidth />
            <UrlField source="imageUrl" />
            <UrlField source="twitterUrl" />
            <UrlField source="linkedinUrl" />
            <DateField label="Created at" source="createdAt" showTime/>
            <DateField label="Updated at" source="updatedAt" showTime/>
        </SimpleShowLayout>
    </Show>
);

export const PeopleList = props => (
    <List {...props} exporter={false} bulkActionButtons={false}  perPage={25} pagination={<CustomPagination />} >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="name" />
            <DateField label="Updated at" source="updatedAt" showTime/>
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
);

export const PeopleCreate = (props) => {
    const redirect = `/peoples` || "edit";

    return (
        <Create {...props} >
            <SimpleForm redirect={redirect}>
                <TextInput source="name"  validate={[maxLength(64)]} />
                <TextInput source="imageUrl" validate={[maxLength(512)]} />
                <TextInput source="twitterUrl" validate={[maxLength(512)]} />
                <TextInput source="linkedinUrl" validate={[maxLength(512)]} />
                <RichTextInput options={quillOptions} source="description" validate={[required(),maxLength(1024)]} />
            </SimpleForm>
        </Create>
    )
};

export const PeopleEdit = (props) => {
    const redirect = `/peoples` || "edit";

    return (
        <Edit {...props}>
            <SimpleForm redirect={redirect}>
                <TextInput source="name"  validate={[maxLength(64)]} />
                <TextInput source="imageUrl" validate={[maxLength(512)]} />
                <TextInput source="twitterUrl" validate={[maxLength(512)]} />
                <TextInput source="linkedinUrl" validate={[maxLength(512)]} />
                <RichTextInput options={quillOptions} source="description" validate={[required(),maxLength(1024)]} />
            </SimpleForm>
        </Edit>
    )
};
