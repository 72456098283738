import React from 'react';
import { Admin, Resource, EditGuesser} from 'react-admin';
import { CompanyList, CompanyShow, CompanyEdit } from './resources/companies.js';
import { GoogleTrendList, GoogleTrendShow, GoogleTrendCreate, GoogleTrendEdit } from './resources/googleTrend.js';
import { ShareOfSearchList, ShareOfSearchShow, ShareOfSearchCreate, ShareOfSearchEdit } from './resources/shareOfSearch.js';
import { ShareOfSearchKeywordEdit } from './resources/shareOfSearchKeywords.js';
import { MetricTypeList, MetricTypeShow } from './resources/metricType.js';
import { FeedEventTypeList, FeedEventTypeShow } from './resources/feedEventType.js';
import { FeedConfigList, FeedConfigShow, FeedConfigEdit } from './resources/feedConfig.js';
import { WebsiteList, WebsiteShow, WebsiteCreate, WebsiteEdit } from './resources/website.js';
import { AppList, AppShow, AppCreate, AppEdit } from './resources/app.js';
import { ScreenerList, ScreenerShow, ScreenerCreate, ScreenerEdit } from './resources/screener.js';
import { PeopleList, PeopleShow, PeopleCreate, PeopleEdit } from './resources/people.js';
import { ArticleList, ArticleShow, ArticleEdit, ArticleCreate } from './resources/article.js';
import { UserList, UserShow, UserEdit } from './resources/user.js';
import { WatchlistStatsList } from './resources/watchlistStats.js';
import { InstitutionList, InstitutionShow, InstitutionEdit } from './resources/institutionalInvestmentManagers.js';
import Dashboard from './dashboard/dashboard.js';
import dataProvider from './dataProvider';
import authProvider from './authProvider';


const App = () => (
    <Admin title="beat.market" dashboard={Dashboard} dataProvider={dataProvider} authProvider={authProvider}>
        <Resource name="companies" list={CompanyList} show={CompanyShow} edit={CompanyEdit} />
        <Resource name="google_trend" list={GoogleTrendList} show={GoogleTrendShow} create={GoogleTrendCreate} edit={GoogleTrendEdit} />
        <Resource name="share_of_search" list={ShareOfSearchList} show={ShareOfSearchShow} create={ShareOfSearchCreate} edit={ShareOfSearchEdit} />
        <Resource name="metric_types" show={MetricTypeShow} />
        <Resource name="feed_event_types" show={FeedEventTypeShow} />
        <Resource name="feed_config" list={FeedConfigList} show={FeedConfigShow} edit={FeedConfigEdit} />
        <Resource name="websites" list={WebsiteList} show={WebsiteShow} create={WebsiteCreate} edit={WebsiteEdit} />
        <Resource name="apps" list={AppList} show={AppShow} create={AppCreate} edit={AppEdit} />
        <Resource name="users" list={UserList} show={UserShow} edit={UserEdit} />
        <Resource name="watchlist-stats" list={WatchlistStatsList} />
        <Resource name="institutional_investment_managers" list={InstitutionList} show={InstitutionShow} edit={InstitutionEdit} options={{ label: 'Hedge funds' }} />
        <Resource name="screeners" list={ScreenerList} show={ScreenerShow} create={ScreenerCreate} edit={ScreenerEdit} options={{ label: 'Screener Pages' }} />
        <Resource name="articles" list={ArticleList} show={ArticleShow} create={ArticleCreate} edit={ArticleEdit} options={{ label: 'Articles' }} />
        <Resource name="peoples" list={PeopleList} show={PeopleShow} create={PeopleCreate} edit={PeopleEdit} options={{label: "People"}}/>
        <Resource name="website_actions" />
        <Resource name="data_for_seo_country_languages" />
        <Resource name="share_of_search_keywords" edit={ShareOfSearchKeywordEdit} />
        <Resource name="data_for_seo_countries" />
        <Resource name="countries" />
        <Resource name="company_sectors"/>
        <Resource name="article_categories"/>
        <Resource name="tags"/>
        <Resource name="jobs"/>
    </Admin>
);

export default App;
